import * as React from "react"
import Layout from "../components/layout"
import Wrapper from "../components/Resultados/wrapper"


const IndexPage = () => (
    <Layout>
      <Wrapper />
    </Layout>
)

export default IndexPage
